import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const HomeSocios = () => {
  return (
    <StaticQuery
      query={graphql`
        query InicioSocios {
          datoCmsPGinaDeInicioSocio {
            titulo
            subtitulo
            tituloAldo
            tituloMariaLourdes
            bioCortaAldo
            bioCortaMariaLourdes
            fotoAldo {
              url
            }
            fotoMariaLourdes {
              url
            }
          }
        }
      `}
      render={data => (
        <div className="w-10/12 lg:w-full mx-auto mt-5 mb-8">
          <h5 className="uppercase text-xs text-gray-600">
            {data.datoCmsPGinaDeInicioSocio.subtitulo}
          </h5>
          <h1 className="text-3xl">{data.datoCmsPGinaDeInicioSocio.titulo}</h1>

          <div className="lg:flex mt-12">
            <div className="w-full lg:w-1/2 flex">
              <div className="hidden lg:block w-1/3 p-5 pl-0">
                <img src={data.datoCmsPGinaDeInicioSocio.fotoAldo.url} alt="" />
              </div>
              <div className="w-full lg:w-2/3 lg:pt-5">
                <h2 className="text-xl">
                  {data.datoCmsPGinaDeInicioSocio.tituloAldo}
                </h2>
                <p className="mt-8 text-gray-800">
                  {data.datoCmsPGinaDeInicioSocio.bioCortaAldo}
                </p>
                <Link
                  to="aldo-milano-sanchez"
                  className="inline-block mt-8 py-3 px-5 bg-gray-200 text-sm hover:bg-gray-900 hover:text-gray-100 montserrat transition duration-300 ease-in "
                >
                  Ver Su Perfil
                </Link>
              </div>
            </div>

            <div className="w-full lg:w-1/2 flex mt-8 lg:mt-0">
              <div className="hidden lg:block w-1/3 p-5 pl-0">
                <img
                  src={data.datoCmsPGinaDeInicioSocio.fotoMariaLourdes.url}
                  alt=""
                />
              </div>
              <div className="w-full lg:w-2/3 lg:pt-5">
                <h2 className="text-xl">
                  {data.datoCmsPGinaDeInicioSocio.tituloMariaLourdes}
                </h2>
                <p className="mt-8 text-gray-800">
                  {data.datoCmsPGinaDeInicioSocio.bioCortaMariaLourdes}
                </p>
                <Link
                  to="maria-lourdes-echandi-gurdian"
                  className="inline-block mt-8 py-3 px-5 bg-gray-200 text-sm hover:bg-gray-900 hover:text-gray-100 montserrat transition duration-300 ease-in "
                >
                  Ver Su Perfil
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default HomeSocios
